/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
// assets/js/Constants.js

var moment = require('moment-timezone');

let Constants = {
  //  install
  install(Vue) {
    //  create global Constants
    Vue.prototype.Constants = {
      VERSION: 1.5,
      MEET_TIME_ZONE : moment.tz.guess(),
      UP_ARROW : 38,
      DOWN_ARROW : 40,
      LEFT_ARROW : 37,
      RIGHT_ARROW : 39,
      ENTER: 13,
      TASK_TYPE: {
        TYPE_1 : 'Action',
        TYPE_2 : 'Change Request',
        TYPE_3 : 'Decision',          
        TYPE_4 : 'Issue',          
        TYPE_5 : 'Notes',          
        TYPE_6 : 'Risk',          
      },
      INFINITE_SCROLL:{WORKBENCH_PAGINATION:10},
      PRIORITY:{TYPE_1:'Critical',TYPE_2:'High',TYPE_3:'Low',TYPE_4:'Medium'},
      MIN_STATUS_TYPE : ['Accepted','Done','In Progress','Not Accepted','On Hold','Open'],
      SELECT_ALL : 'All'
    };
  }
};

export default Constants;

