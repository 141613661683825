import Vue from 'vue';
import App from '@/App.vue';
import Constants from '@/assets/config/constants';
import router from '@/assets/config/routes';
import store from '@/assets/config/store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '/public/style.css';

export const bus = new Vue();

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(require('vue-shortkey'))

Vue.config.productionTip = false
Vue.use(Constants);
new Vue({
    router,
    store,
    created() {
        if (window.location.hostname === 'localhost') {
            this.$store.commit('setUrl', 'http://localhost:2920/');
        } else {
            this.$store.commit('setUrl', window.location.protocol + '//' + window.location.host + '/prominutes_backend/');
        }
    },
    render: h => h(App)
}).$mount('#app')
