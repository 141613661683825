/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        count: 0,
        login_status : '',
        todos: [
            {id: 1, text: '...', done: true},
            {id: 2, text: '...', done: false}
        ],
        api: '',
        to_path : '',
        from_path : '',
        menu_expand : false,
        meeting_details : {},
        organisation_members : [],
        meeting_participates : [],
        added_participate : {},
        delete_participate : {},
        added_agenda_data : {},
        all_minutes : [],
        meeting_minutes_details : {},
        meeting_participants : [],
        org_id : sessionStorage.getItem('organization_details') ? JSON.parse(sessionStorage.getItem('organization_details')).org_id : '' ,
        org_name : sessionStorage.getItem('organization_details') ? JSON.parse(sessionStorage.getItem('organization_details')).org_name : '' ,
        organiser_id : sessionStorage.getItem('organization_details') ? JSON.parse(sessionStorage.getItem('organization_details')).organiser_id : '' ,
        all_meeting_minutes : {},
        loader_popup: false,
        minutes_records_obj:{},
        minutes_obj_count:{},
        meetings_records_obj:{},
        login_details_obj:{},
        changelog_obj:{},
        groups_obj : [],
        save_group_data : {},
        get_filter_obj:{},
        save_filter_obj:{},
        delete_filter_obj:{},
        favourite_filter_obj:{},
        get_filter_details_obj:{},
        all_meetings_and_records : {}
    },
    actions:{
        CheckLoginDtails({
            commit
        },[email_id,password]){
            return new Promise((resolve,reject) => {
                axios.get(`/login.json`).then(response => {
                    commit('loginCheck', [response.data,email_id,password]);
                    resolve(response.data)
                },error => {
                    reject(error)
                })
            })
        },

        getLoginDetails({
            commit
        },[api,loginObj]){
            return new Promise((resolve,reject)=>{
                axios.post(api + `members/get_org_id`,
                loginObj,
                ).then(response => {
                    commit('loginDetails', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },


        getOrgMembers({
            commit
        },[api,org_id]){
            return new Promise((resolve,reject)=>{
                axios.post(api + `members/get_org_members`, {
                    "org_id": org_id,
                }).then(response => {
                    commit('orgMembers', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        CrudMeeting({
            commit
        },[api,OrgmeetDetails]){
            return new Promise((resolve,reject)=>{
                axios.post(api + `meeting/save_meeting`,
                    OrgmeetDetails,
                ).then(response => {
                    commit('saveMeeting', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        SaveGroup({
            commit
        },[api,group_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api + `groups/save_group`,
                    group_obj,
                ).then(response => {
                    commit('saveGroup', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        addParticipatestoMeeting({
            commit
        },[api,participate_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`participants/add_attendees`,
                    participate_obj
                ).then(response => {
                    commit('addParticipates', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        saveAgendaMeeting({
            commit
        },[api,agenda_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`minutes/save_agenda_minutes`,
                agenda_obj
                ).then(response => {
                    commit('saveAgenda', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        minuteShuffle({
            commit
        },[api,shuffle_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`minutes/update_minutes_order`,
                    shuffle_obj
                ).then(response => {
                    commit('saveShuffle', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        getAllMinutes({
            commit
        },[api,meet_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`meeting/get_meetings_and_minutes`,
                    meet_obj
                ).then(response => {
                    commit('getMinutes', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        getMeetingMinutes({
            commit
        },[api,meet_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`meeting/get_meeting_minutes_details`,
                    meet_obj
                ).then(response => {
                    commit('getMeetingDetails', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        getMeetingMinutesDownload({commit},[api,meet_obj]){
            console.log(commit)
            return new Promise((resolve,reject)=>{
                axios.post(api+`meeting/download_meeting_minutes`,
                    meet_obj
                ).then(response => {
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        getMeetingParticipants({
            commit
        },[api,meet_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`participants/get_meeting_participants`,
                    meet_obj
                ).then(response => {
                    commit('getParticipates', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        getMeetingAllMinutes({
            commit
        },[api,meet_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`minutes/get_all_meeting_minutes_filters`,
                    meet_obj
                ).then(response => {
                    commit('getMeetingMinutes', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        getAllMinutesRecords({
            commit
        },[api,meet_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`meeting/get_meetings_and_minutes_new_filters`,
                    meet_obj
                ).then(response => {
                    commit('getAllMinutesRecords', response.data);
                    resolve(response.data) 
                },error => {
                    reject(error)
                })
            })
        },

        getAllMinutesCount({
            commit
        },[api,meet_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`meeting/get_meetings_and_minutes_count`,
                    meet_obj
                ).then(response => {
                    commit('getAllMinutesCount', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        getAllMeetingsRecords({
            commit
        },[api,meet_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`meeting/get_meetings_data`,
                    meet_obj
                ).then(response => {
                    commit('getAllMeetingsRecords', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        getAllMeetingsAndGroups({
            commit
        },[api,meet_obj]){
            return new Promise((resolve,reject)=>{
                axios.post(api+`meeting/get_meetings_and_groups`,
                    meet_obj
                ).then(response => {
                    commit('getAllMeetingsAndGroups', response.data.result);
                    resolve(response.data.result) 
                },error => {
                    reject(error)
                })
            })
        },

        deleteParticipate({
            commit
        },[api,delete_obj]){
            return new Promise((resolve,reject) => {
                axios.post(api + `participants/delete_participants`,
                    delete_obj
                ).then(response => {
                    commit('deleteParticipateData', response.data.result);
                    resolve(response.data)
                },error => {
                    reject(error)
                })
            })
        },

        changelogDetails({
            commit
        },[api,changelog_obj]){
            return new Promise((resolve,reject) => {
                axios.post(api + `change_log/get_change_log_details`,
                    changelog_obj
                ).then(response => {
                    commit('changelogData', response.data.result);
                    resolve(response.data)
                },error => {
                    reject(error)
                })
            })
        },

        getGroups({
            commit
        },[api,groups_obj]){
            return new Promise((resolve,reject) => {
                axios.post(api + `groups/get_groups`,
                    groups_obj
                ).then(response => {
                    commit('groupsData', response.data.result);
                    resolve(response.data)
                },error => {
                    reject(error)
                })
            })
        },

        getFilters({
            commit
        },[api,filterobj]){
            return new Promise((resolve,reject) => {
                axios.post(api + `filters/get_filters`,
                    filterobj
                ).then(response => {
                    commit('getFilterData', response.data.result);
                    resolve(response.data)
                },error => {
                    reject(error)
                })
            })
        },

        saveFilter({
            commit
        },[api,filterobj]){
            return new Promise((resolve,reject) => {
                axios.post(api + `filters/save_filter`,
                    filterobj
                ).then(response => {
                    commit('saveFilterData', response.data.result);
                    resolve(response.data)
                },error => {
                    reject(error)
                })
            })
        },

        getFavouriteCards({
            commit
        },[api,filterobj]){
            return new Promise((resolve,reject) => {
                axios.post(api + `filters/get_filter_cards`,
                    filterobj
                ).then(response => {
                    commit('getFavouriteFilterData', response.data.result);
                    resolve(response.data)
                },error => {
                    reject(error)
                })
            })
        },

        getFiltersDetails({
            commit
        },[api,filterobj]){
            return new Promise((resolve,reject) => {
                axios.post(api + `filters/get_filter_details`,
                    filterobj
                ).then(response => {
                    commit('getFilterDetailsData', response.data.result);
                    resolve(response.data)
                },error => {
                    reject(error)
                })
            })
        },


    },
    mutations: {

        deleteParticipateData(state,delete_participate_data){ 
            return state.delete_participate = delete_participate_data;
        },

        loginCheck(state,form_details){
            if(form_details[0].email == form_details[1] && form_details[0].password == form_details[2]){
                state.login_status = 'Success';
            }else{
                state.login_status = 'Fail';
            }
        },

        groupsData(state,groups){
            return state.groups_obj = groups;
        },

        loginDetails(state,login_details_obj){
            return state.login_details_obj = login_details_obj;
        },

        getMeetingDetails(state,meeting_minutes){
            return state.meeting_minutes_details = meeting_minutes;
        },

        getMeetingMinutes(state,meeting_minutes){
            meeting_minutes.forEach((minute) => {
                minute.participants.forEach((participants_list,index)=>{
                    Object.assign(minute.participants[index], {"full_name":participants_list.org_member_id.full_name});
                })    
            });
            return state.all_meeting_minutes = meeting_minutes;
        },

        getParticipates(state,participants){
            participants.forEach(member => {
                member.full_name = member.details.full_name;
                member.html_name="<span class='text-break'>"+member.full_name+"&nbsp;</span><br>"+"<small class='grey'>"+member.details.email+"</small>"
            });
            return state.meeting_participants = participants;
        },

        getMinutes(state,minutesdetails){
            return state.all_minutes = minutesdetails;
        },

        saveMeeting(state, meetingdetails){
            return state.meeting_details = meetingdetails;
        },

        saveShuffle(state,shuffle_obj){
            return state.shuffle_minute = shuffle_obj;
        },

        saveGroup(state,group_info){
            return state.save_group_data = group_info;
        },

        orgMembers(state,membersOrg){
            membersOrg.forEach(member=>{
                member.html_name = "<span class='text-break'>"+member.full_name+"</span>&nbsp;<br>"+"<small class='grey'>"+member.email+"</small>"
            })
            return state.organisation_members = membersOrg;
        },

        addParticipates(state,participate_obj){ 
            return state.added_participate = participate_obj;
        },

        saveAgenda(state,agenda_obj){
            return state.added_agenda_data = agenda_obj
        },

        getAllMinutesRecords(state,records_obj){
            return state.minutes_records_obj = records_obj 
        },

        getAllMinutesCount(state,records_obj){
            return state.minutes_obj_count = records_obj 
        },

        getAllMeetingsRecords(state,meetings_records_obj){
            return state.meetings_records_obj = meetings_records_obj; 
        },

        getFilterData(state,get_filter_obj){
            return state.get_filter_obj = get_filter_obj;
        },
         
        saveFilterData(state,save_filter_obj){
            return state.save_filter_obj = save_filter_obj;
        },

        getFavouriteFilterData(state,favourite_filter_obj){
            return state.favourite_filter_obj = favourite_filter_obj;
        },

        deleteFilterData(state,delete_filter_obj){
            return state.delete_filter_obj = delete_filter_obj;
        },

        getAllMeetingsAndGroups(state,meetings_and_groups){
            return state.all_meetings_and_records = meetings_and_groups; 
        },

        getFilterDetailsData(state,get_filter_details_obj){
            return state.get_filter_details_obj = get_filter_details_obj;
        }, 

        updateAttendance(state,attend_mem_obj){
            axios.post(state.api + `participants/update_attendance_status`,
                attend_mem_obj
            ).then(response => {
                return response.data
            })
        },

        deleteMeeting(state,delete_obj){
            axios.post(state.api + `meeting/delete_meeting`,
                delete_obj
            ).then(response => {
                return response.data
            })
        },

        deleteGroup(state,delete_grp_obj){
            axios.post(state.api + `groups/delete_group`,
                delete_grp_obj
            ).then(response => {
                return response.data
            })
        },

        deleteFilter(state,delete_filter_obj){
            axios.post(state.api + `filters/delete_filter`,
            delete_filter_obj
            ).then(response => {
                return response.data
            })
        },

        changelogData(state,changelog_obj){
            return state.changelog_obj = changelog_obj;
        },

        increment(state) {
            state.count++
        },
        
        setUrl(state,url){
            state.api = url;
        }
        
    },
    getters:{
        doneTodos: state => {
            return state.todos.filter(todo => todo.done)
        },

        search_org_members : state => (search_text)=>{
            let member_data = [...state.organisation_members];
               
            member_data = member_data.filter(item => item._id != state.organiser_id);
            
            return member_data.filter(data =>{
                return data.full_name.toLowerCase().includes(search_text.toLowerCase());
            })
        },

        mail_exist_in_members : state => (mail) => {
            return state.organisation_members.find(member => member.email == mail);
        },

        members_attended : state => {
            return state.meeting_participants.reduce((a,e)=>{
                if(e.attendance_status){
                    a.push(e._id);
                }
                return a;
            },[])
        },
 
    }
})
