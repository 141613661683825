/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/assets/config/store';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import('../../vues/login/login.vue'),
        meta: {
            title: 'Login | ProMinutes',
            role: 'Admin',
            metaTags: [
                {
                    name: 'Login',
                    content: 'Login to Prominutes.'
                }
            ]
        }
    },
    {
        path: '/test',
        component: () => import('../../vues/route2/route2.vue'),
    },
    {
        path: '/home',
        component: () => import('../../vues/home/home.vue'),
        meta: {
            title: 'Home | ProMinutes',
            role: 'Admin',
            metaTags: [
                {
                    name: 'Home',
                    content: 'Home Layout.'
                }
            ]
        }
    },
    {
        path: '/instantmeeting',
        component: () => import('../../vues/InstantMeeting/instant_meeting.vue'),
        meta: {
            title: 'Instant Meeting | Prominutes',
            role: 'Admin',
            metaTags: [
              {
                name: 'Instant Meeting',
                content: 'Start an Instant Meeting.',
              }
            ]
        }
    },
    {

        path  : '/workbench',
        component:  () => import('../../vues/workbench/work_bench.vue'),
        // alias: '/meetings',
        meta: {
            title: 'WorkBench | Prominutes',
            role: 'Admin',
            metaTags: [
              {
                name: 'WorkBench',
                content: 'We can see all the details of Meetings and Minutes.',
              }
            ]
        }
    },
    {
        path: '/meetings',
        component: () => import('../../vues/workbench/work_bench.vue'),
        meta: {
            title: 'My Meetings | Prominutes',
            role: 'Admin',
            metaTags: [
              {
                name: 'My Meetings',
                content: 'We can see all the details of Meeting.',
              }
            ]
        }
    },
    {
        path: '/groups',
        component: () => import('../../vues/groups/groups.vue'),
        meta: {
            title: 'Groups | Prominutes',
            role: 'Admin',
            metaTags: [
              {
                name: 'My Groups',
                content: 'We can see all groups.',
              }
            ]
        }
    },
    {
        path: '/viewminutes/:meeting_id',
        component: () => import('../../vues/ViewMinutes/view_minutes.vue'),
        meta: {
            title: 'Published Minutes | Prominutes',
            role: 'Admin',
            metaTags: [
              {
                name: 'Published Minutes',
                content: 'We can see all published details of Meeting.',
              }
            ]
        }
    },
    {
        path: '/draftminutes/:meeting_id',
        component: () => import('../../vues/DraftMeeting/draft_meeting.vue'),
        meta: {
            title: 'Draft Minutes | Prominutes',
            role: 'Admin',
            metaTags: [
              {
                name: 'Draft Minutes',
                content: 'We can see all draft details of Meeting.',
              }
            ]
        }
    }
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes, // short for `routes: routes`
    store
});


router.beforeEach((to,from,next) => {
    let DEFAULT_TITLE = 'ProMinutes';
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });

    store.state.to_path = to.path.toLowerCase();
    store.state.from_path = from.path.toLowerCase();

    next();
})
export default router;